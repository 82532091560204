/* styles.css */
.ant-layout-header {
  background: #fff;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.ant-layout-content {
  padding: 60px 50px;
  background: #f0f2f5;
}

.ant-layout-footer {
  text-align: center;
  background: #fff;
}

.element {
  padding: 50px 0;
}

.element > div {
  margin: 50px 0;
}
.section {
  padding: 50px 0; /* Adjust the value as needed */
}

.carousel-description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  text-align: center;
}

/* Carousel styles */
.ant-carousel .slick-slide {
  text-align: center;
  height: 400px; /* Adjust the height as needed */
  line-height: 400px; /* Same as height */
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}

/* styles.css */
.legend {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.project-details {
  text-align: center;
  margin-bottom: 20px;
}

.image-container {
  width: 100%;
  height: 200px; /* Adjust the height as needed */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px; /* Add border-radius to round the corners */
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px; /* Add border-radius to round the corners */
}

.purple-menu .ant-menu-item a {
  color: purple;
}

.purple-menu .ant-menu-item:hover, 
.purple-menu .ant-menu-item-active, 
.purple-menu .ant-menu-item-selected {
  background-color: #f0f0f0; /* Optional: change the background color on hover */
  color: purple;
}

.purple-menu .ant-menu-item:hover a, 
.purple-menu .ant-menu-item-active a, 
.purple-menu .ant-menu-item-selected a {
  color: purple;
}

#home {
  position: relative;
  width: 100%;
  min-height: 100vh;  /* Ensure the container covers the viewport height */
  overflow: hidden;   /* Prevents any overflow issues */
}


.project-page {
  max-width: 80vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px; /* Adjust this value as needed */
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  width: 100%;
  max-width: 800px;
  justify-content: center;
}

.image-container {
  width: 100%;
  height: 250px; /* Adjust the height as needed */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px; /* Add border-radius to round the corners */
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px; /* Add border-radius to round the corners */
}

.project-card {
  margin: 0 auto; /* Centers the card horizontally */
  max-width: 100%; /* Ensures the card doesn't exceed the available width */
}

.project-cards-container {
  margin: 0 -10px; /* Negative margin to compensate for padding */
}

.project-cards-container .ant-col {
  padding: 0 10px; /* Add padding to create space between cards */
}

@media (max-width: 576px) {
  /* Styles for phone screens */
  .project-cards-container .ant-col {
    padding: 10px; /* Adjust the padding value as needed */
  }
}

#brands {
  padding: 20px;
  text-align: center;
}

.brands-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px; /* Controls the spacing between the images */
}

.brands-grid-item {
  flex: 0 1 calc(30.333% - 12px); /* 3 images per row with 8px gap */
  box-sizing: border-box;
}

.brands-grid-item img {
  width: 100%;
  height: auto;
  display: block;
}
